import React from 'react'
import {Modal, Button, } from 'react-bootstrap'
import { useParams, useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'

import './MessageUpdate.css'

const MessageUpdate = (props) => {
  const sysId = useParams().sysId;
  const address = useLocation();
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           System Updated
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Your system has been entered</h4>
          <p>
            Success. 
          </p>
          { (sysId)  ? <LinkContainer to={(address.pathname === `/${sysId}/editsystem`) ?  `/${sysId}/systemInfoCDS` : `/system/backend/${sysId}/systemInfoCDSadmin`}> 
        <Button variant="primary" onClick={props.onHide} >Return to System</Button>
    </LinkContainer>: <LinkContainer to="/system/search"> 
        <Button variant="primary" onClick={props.onHide} >Return to Search</Button>
    </LinkContainer> }
             
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default MessageUpdate
