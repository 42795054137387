import React, { useState, useContext } from "react";
import { Form, Button, Col, Alert, Spinner } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { AuthContext } from "../../shared/context/auth-context";

import useVisibilityToggler from "../../hooks/useVisibilityToggler";

import "./SearchSystem.css";

const SearchSystem = (props) => {
	const [enteredSearchValue, setEnteredSearchValue] = useState("");
	const auth = useContext(AuthContext);

	const { isLoading, error, errorModalShow, setError, setErrorModalShow, sendRequest } = useHttpClient();

	const searchValueChangeHadler = (event) => {
		hide();
		hideNoResult();
		setEnteredSearchValue(event.target.value.toLowerCase());
	};

	const searchSystemHandler = async (event) => {
		event.preventDefault();
		setError(null);
		props.updateResults([]);
		let filteredSystemList;

		if (enteredSearchValue) {
			let cleanEnteredSearchValue = encodeURIComponent(enteredSearchValue.trim());

			try {
				hide();
				hideNoResult();
				filteredSystemList = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/systems/search/${cleanEnteredSearchValue}`, "GET", null, {
					Authorization: "Bearer " + auth.token,
				});
				props.updateResults(filteredSystemList.system);
				setEnteredSearchValue("");
				filteredSystemList = [];
			} catch (err) {
				showNoResult();
			}
		} else {
			show();
			hideNoResult();
			props.updateResults([]);
		}
	}; // <----  End searchSystemHandler ----->

	const clearSearchResualts = () => {
		let filteredSystemListNew = [];
		setEnteredSearchValue("");
		hideNoResult();
		hide();
		setError(null);
		props.updateResults(filteredSystemListNew);
	};

	const [alertConponent, show, hide] = useVisibilityToggler(
		<Alert variant='danger' onClose={() => hide()} dismissible>
			Please enter a Serial Number
		</Alert>,
		false
	);

	const [alertConponentInfo, showNoResult, hideNoResult] = useVisibilityToggler(
		<Alert variant='warning' onClose={() => hideNoResult()} dismissible>
			No system found
		</Alert>,
		false
	);

	return (
		<div>
			<ErrorMessageModel
				show={errorModalShow}
				onHide={() => {
					setErrorModalShow(false);
				}}
				message={error}
			/>
			<Form onSubmit={searchSystemHandler}>
				<Form.Row>
					<Form.Group as={Col} controlId='formGridSearchSystem'>
						<div className='alertHight'>
							{" "}
							{alertConponent} {alertConponentInfo}{" "}
						</div>
						{isLoading && (
							<div className='spinner-loading'>
								<Spinner animation='border' variant='primary' /> <span>Loading...</span>
							</div>
						)}
						{error && <Alert variant='danger'> {error} </Alert>}
						<Form.Label>Search by Serial Number </Form.Label>
						<Form.Control onChange={searchValueChangeHadler} value={enteredSearchValue} type='text' placeholder='Enter serial number' />
					</Form.Group>
				</Form.Row>

				<Button variant='primary' type='submit'>
					Search
				</Button>
				<Button variant='dark' className='mx-3' onClick={clearSearchResualts}>
					{" "}
					Clear
				</Button>
			</Form>
		</div>
	);
};

export default SearchSystem;
