import React, { useState, useContext } from "react";
import { Form, Button, Alert, Spinner, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import useVisibilityToggler from "../../hooks/useVisibilityToggler";

import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { useHttpClient } from "../../hooks/http-hook";

import "./Login.css";

const Login = () => {
	const [loginEmailValue, setLoginEmailValue] = useState("");
	const [loginPasswordValue, setLoginPasswordValue] = useState("");
	const [loginPasswordVerifyValue, setLoginPasswordVerifyValue] = useState("");
	const [signUpFirstName, setSignUpFirstName] = useState("");
	const [signUpLastName, setSignUpLastName] = useState("");
	const [isLoginMode, setIsLoginMode] = useState(true);

	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	const auth = useContext(AuthContext);
	let history = useHistory();

	const cdsLogin = async (event) => {
		event.preventDefault();
		hide();

		if (!isLoginMode) {
			if (loginPasswordValue !== loginPasswordVerifyValue) {
				show();
				return;
			}

			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/api/users/signup/`,
					"POST",
					JSON.stringify({
						userFirstName: signUpFirstName,
						userLastName: signUpLastName,
						userEmail: loginEmailValue,
						userPassword: loginPasswordValue,
						userPasswordConfirm: loginPasswordVerifyValue,
					}),

					{
						"Content-Type": "application/json",
					}
				);
				auth.login(responseData.userId, responseData.token, responseData.userEmail, responseData.admin);
				history.push("/system/search");
			} catch (err) {}
		} else {
			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/api/users/login/`,
					"POST",
					JSON.stringify({
						userEmail: loginEmailValue,
						userPassword: loginPasswordValue,
					}),
					{
						"Content-Type": "application/json",
					}
				);

				auth.login(responseData.userId, responseData.token, responseData.userEmail, responseData.admin);

				history.push("/system/search");
			} catch (err) {}
		}
	};

	const switchLoginHandler = () => {
		setIsLoginMode((preMode) => !preMode);
	};

	const firstNameChangeHandler = (event) => {
		setSignUpFirstName(event.target.value);
	};
	const lastNameChangeHandler = (event) => {
		setSignUpLastName(event.target.value);
	};

	const emailChangeHandler = (event) => {
		setLoginEmailValue(event.target.value);
	};

	const passwordChangeHandler = (event) => {
		setLoginPasswordValue(event.target.value);
	};
	const passwordVerifyChangeHandler = (event) => {
		setLoginPasswordVerifyValue(event.target.value);
	};

	const passwordVisibility = () => {
		var passwordInput = document.getElementById("formBasicPassword");
		if (passwordInput.type === "password") {
			passwordInput.type = "text";
			showPasswordEye();
			hidePasswordEyeSlash();
		} else {
			passwordInput.type = "password";
			hidePasswordEye();
			showPasswordEyeSlash();
		}
	};

	const passwordConfirmVisibility = () => {
		var passwordConfirmInput = document.getElementById("formBasicVerifyPassword");
		if (passwordConfirmInput.type === "password") {
			passwordConfirmInput.type = "text";
			showPasswordConfirmEye();
			hidePasswordConfirmEyeSlash();
		} else {
			passwordConfirmInput.type = "password";
			hidePasswordConfirmEye();
			showPasswordConfirmEyeSlash();
		}
	};

	const [alertPasswordConponent, show, hide] = useVisibilityToggler(
		<Alert variant='danger' onClose={() => hide()} dismissible>
			Passwords Don't Match.
		</Alert>,
		false
	);

	const [seePasswordEye, showPasswordEye, hidePasswordEye] = useVisibilityToggler(<i className='far fa-eye'></i>, false);

	const [seePasswordEyeSlash, showPasswordEyeSlash, hidePasswordEyeSlash] = useVisibilityToggler(<i className='far fa-eye-slash'></i>, true);

	const [seePasswordConfirmEye, showPasswordConfirmEye, hidePasswordConfirmEye] = useVisibilityToggler(<i className='far fa-eye'></i>, false);

	const [seePasswordConfirmEyeSlash, showPasswordConfirmEyeSlash, hidePasswordConfirmEyeSlash] = useVisibilityToggler(
		<i className='far fa-eye-slash'></i>,
		true
	);

	return (
		<>
			{isLoginMode ? <h2> Login Required</h2> : <h2>Sign UP</h2>}
			<div className='loginBox'>
				{isLoading && (
					<div className='spinner-loading'>
						<Spinner animation='border' variant='primary' /> <span>Loading...</span>
					</div>
				)}

				<ErrorMessageModel
					show={errorModalShow}
					onHide={() => {
						setErrorModalShow(false);
					}}
					message={error}
				/>

				<Form className='loginCenter px-5' onSubmit={cdsLogin}>
					<div className='alertHight'> {alertPasswordConponent} </div>
					{!isLoginMode && (
						<Form.Group controlId='formBasicName'>
							<Form.Label>First Name</Form.Label>
							<Form.Control
								required
								type='text'
								placeholder='First name'
								autoComplete='off'
								value={signUpFirstName}
								onChange={firstNameChangeHandler}
							/>
						</Form.Group>
					)}{" "}
					{!isLoginMode && (
						<Form.Group controlId='formBasicLastName'>
							<Form.Label>Last Name</Form.Label>
							<Form.Control required type='text' placeholder='Last name' autoComplete='off' value={signUpLastName} onChange={lastNameChangeHandler} />
						</Form.Group>
					)}
					<Form.Group controlId='formBasicEmail'>
						<Form.Label>Email address</Form.Label>
						<Form.Control
							required
							type='email'
							placeholder='Enter your Email'
							autoComplete='off'
							value={loginEmailValue}
							onChange={emailChangeHandler}
						/>
						<Form.Text className='text-muted'>We'll never share your email with anyone else.</Form.Text>
					</Form.Group>
					<Form.Group controlId='formBasicPassword'>
						<Form.Label>Password</Form.Label>
						<InputGroup className='mb-2'>
							<Form.Control
								required
								minLength='5'
								type='password'
								placeholder='Password. Enter a minimum of 5 characters'
								autoComplete='off'
								value={loginPasswordValue}
								onChange={passwordChangeHandler}
							/>
							<InputGroup.Text className='eye-background' onClick={passwordVisibility}>
								<div>
									{seePasswordEye} {seePasswordEyeSlash}{" "}
								</div>{" "}
							</InputGroup.Text>
						</InputGroup>
					</Form.Group>
					{!isLoginMode && (
						<Form.Group controlId='formBasicVerifyPassword'>
							<Form.Label>Verify Password</Form.Label>
							<InputGroup>
								<Form.Control
									required
									minLength='5'
									type='password'
									placeholder='Verify Password. Enter a minimum of 5 characters'
									autoComplete='off'
									value={loginPasswordVerifyValue}
									onChange={passwordVerifyChangeHandler}
								/>
								<InputGroup.Text className='eye-background' onClick={passwordConfirmVisibility}>
									<div>
										{seePasswordConfirmEye} {seePasswordConfirmEyeSlash}{" "}
									</div>{" "}
								</InputGroup.Text>
							</InputGroup>
						</Form.Group>
					)}
					<Button variant='primary' type='submit'>
						{isLoginMode ? "Login" : "SignUp"}
					</Button>{" "}
					<Button variant='info' onClick={switchLoginHandler}>
						Switch to {isLoginMode ? "SignUp" : "Login"}
					</Button>
				</Form>
			</div>
		</>
	);
};

export default Login;
