import React, { useEffect, useState, useContext } from "react";
import { Jumbotron, Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { AuthContext } from "../../shared/context/auth-context";

import "./SystemInfoCDS.css";
import NewLineText from "../components/NewLineText";

/*let testArray = [
  {id: "1", creator: 'u1', customerName: "IBM-Telstra", serialNum: "APM00034872902", address: "7 Flint Locke Road, Randolph MA 02368",  systemAdminName: "", systemAdminEmail: " ", systemAdminPhone: " ", city: " ", state: " ", zip: " ", country: " ", systemType: " ", contactForLogs: " ", contactEmailForLogs: " ", shippingAddress: " ", labor: " ", laborContactEmail: "", laborContactPhone: "",  notes: "", ftp: "" },
  {id: "2", creator: 'u2', customerName: "IBM-State of GA", serialNum: "APM00034872876", address: "31 wall St, Brockton MA 02301", systemAdminName: "", systemAdminEmail: " ", systemAdminPhone: " ", city: " ", state: " ", zip: " ", country: " ", systemType: " ", contactForLogs: " ", contactEmailForLogs: " ", shippingAddress: " ", labor: " ", laborContactEmail: "", laborContactPhone: "",  notes: "", ftp: ""},
  {id: "3", creator: 'u3', customerName: "DellEMC", serialNum: "APM00034872234", address: "134 High St, Randolph MA 02368", systemAdminName: "", systemAdminEmail: " ", systemAdminPhone: " ", city: " ", state: " ", zip: " ", country: " ", systemType: " ", contactForLogs: " ", contactEmailForLogs: " ",contactPhoneForLogs: "", shippingAddress: " ", labor: " ", laborContactEmail: "", laborContactPhone: "", notes: "", ftp: "" },
  {id: "4", creator: 'u1', customerName: "IBM-Telstra", serialNum: "APM00034872902", address: "7 Flint Locke Road",  systemAdminName: " Adam Jackson", systemAdminEmail: "ajackson@test.com ", systemAdminPhone: "781-555-1982 ", city: "Randolph", state: "MA ", zip: "02368 ", country: " US", systemType: "VNX5500 ", contactForLogs: "John Smith ", contactEmailForLogs: "j.smith@example.com ", contactPhoneForLogs: "345-555-2345", shippingAddress: "123 Main street \n Boston, MA 02102 ", labor: "UTC ",laborContactEmail: "service@utc-usa.com", laborContactPhone: "215-555-9087", notes: "For CE access please email \n Email admin-opt@example.com ", ftp: "ftp.cds.net \n User name: ibm-tel \n Password: test123" }
];*/

const SystemInfoCDS = (props) => {
	const sysId = useParams().sysId;
	const auth = useContext(AuthContext);

	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();
	const [system, setSystem] = useState();

	useEffect(() => {
		const getSystem = async () => {
			let cleanSystemIdString = encodeURIComponent(sysId.trim());
			try {
				const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/systems/system/${cleanSystemIdString}`, "GET", null, {
					Authorization: "Bearer " + auth.token,
				});
				setSystem(responseData.system);
			} catch (err) {}
		};
		getSystem();
	}, [sendRequest, sysId, auth.token]);

	let history = useLocation();
	if (!system) {
		return (
			<main>
				<Container>
					<h2> System not found </h2>
					<ErrorMessageModel
						show={errorModalShow}
						onHide={() => {
							setErrorModalShow(false);
						}}
						message={error}
					/>

					{isLoading && (
						<div className='spinner-loading'>
							<Spinner animation='border' variant='primary' /> <span>Loading...</span>
						</div>
					)}

					<Breadcrumb>
						<LinkContainer to={history.pathname === `/${sysId}/systemInfoCDS` ? `/system/search` : `/system/backend/updatesystem`}>
							<Breadcrumb.Item>Search</Breadcrumb.Item>
						</LinkContainer>
						<Breadcrumb.Item active>System Info</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</main>
		);
	}

	return (
		<main>
			<div>
				<Jumbotron className='bg-primary text-white' fluid>
					<h1 className='headerSysInfo'>{system.customerName}</h1>
					<h2 className='headerSysInfo'>{system.serialNum}</h2>
					<p className='paragraphCenter'>System Info Below.</p>
					<ErrorMessageModel
						show={errorModalShow}
						onHide={() => {
							setErrorModalShow(false);
						}}
						message={error}
					/>

					{isLoading && (
						<div className='spinner-loading'>
							<Spinner animation='border' variant='primary' /> <span>Loading...</span>
						</div>
					)}

					<p className='paragraphCenter'>
						<LinkContainer to={history.pathname === `/${sysId}/systemInfoCDS` ? `/system/search` : `/system/backend/updatesystem`}>
							<Button variant='secondary' className='mx-2'>
								Return to Search
							</Button>
						</LinkContainer>
						<LinkContainer
							to={history.pathname === `/${sysId}/systemInfoCDS` ? `/${system.id}/editsystem` : `/system/backend/${system.id}/editsystem`}>
							<Button variant='secondary'>Edit System</Button>
						</LinkContainer>
					</p>
				</Jumbotron>
				<Container>
					<Breadcrumb>
						<LinkContainer to={history.pathname === `/${sysId}/systemInfoCDS` ? `/system/search` : `/system/backend/updatesystem`}>
							<Breadcrumb.Item>Search</Breadcrumb.Item>
						</LinkContainer>
						<Breadcrumb.Item active>System Info</Breadcrumb.Item>
					</Breadcrumb>

					<Row className='mt-5 mb-3'>
						<Col className='py-3' md>
							<Card className='info-height' border='primary'>
								<Card.Header> System Address </Card.Header>
								<Card.Body>
									<Card.Title>{system.serialNum} </Card.Title>
									<Card.Text>
										{system.address}
										<br /> {system.city}, {system.state} {system.zip}
										<br />
										{system.country}
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col md className='py-3'>
							<Card border='danger' className='info-height'>
								<Card.Header>Part Shipping Address</Card.Header>
								<Card.Body>
									<Card.Title> System Type: {system.systemType}</Card.Title>
									<Card.Text></Card.Text>
									<br />
									<NewLineText text={system.shippingAddress} />
								</Card.Body>
							</Card>
						</Col>
					</Row>

					<Row className='py-1'>
						<Col className='py-3' md>
							<Card className='info-height' border='success'>
								<Card.Header>System Admin</Card.Header>
								<Card.Body>
									<Card.Title>System Admin: {system.systemAdminName}</Card.Title>
									<Card.Text>
										<br /> Email: {system.systemAdminEmail}
										<br />
										Phone: {system.systemAdminPhone}
										<br />
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>

						<Col className='py-3' md>
							<Card className='info-height' border='info'>
								<Card.Header>Contact For logs </Card.Header>
								<Card.Body>
									<Card.Title>Contact: {system.contactForLogs} </Card.Title>
									<Card.Text>
										Email : {system.contactEmailForLogs}
										<br />
										Phone : {system.contactPhoneForLogs}
									</Card.Text>
									<Card.Title>FTP info </Card.Title>
									<Card.Text></Card.Text>
									<NewLineText text={system.ftp} />
								</Card.Body>
							</Card>
						</Col>

						<Col className='py-3' md>
							<Card className='info-height' border='success'>
								<Card.Header>Lobor For this System</Card.Header>
								<Card.Body>
									<Card.Title>Name: {system.labor}</Card.Title>
									<Card.Text>
										Labor email: {system.laborContactEmail} <br />
										Labor Phone: {system.laborContactPhone} <br />
										<br />
									</Card.Text>
									<Card.Title> Notes</Card.Title>
									<NewLineText text={system.notes} />
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</main>
	);
};

export default SystemInfoCDS;
