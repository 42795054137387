import React, { useState } from 'react'
import { Form, Button, Alert } from "react-bootstrap"
import './NewGoal.css'
import useVisibilityToggler from '../../hooks/useVisibilityToggler.js'
       
const NewGoal = (props) => {

  const [enteredText, setEnteredText] = useState('');
  

    const addGoalHandler = (event) => {
        event.preventDefault();

       
        if (enteredText){
          const newGoal = {
            id: Math.random().toString(),
            text: enteredText
         }; 
         setEnteredText('');
          props.onAddGoal(newGoal);
        } else {
          show();
        }
        
        
    };

    const textChangeHandler = event => {
      setEnteredText(event.target.value);
    };

const [alertConponent, show, hide] = useVisibilityToggler(<Alert variant='danger' >
Please enter a Goal
</Alert>, false);
 


    return (
        <div>
           <Form onSubmit={addGoalHandler}>
  <Form.Group controlId="formBasicEmail">
  <div className="alertHight"> {alertConponent} </div>
    <Form.Label>New Goal</Form.Label>
    <Form.Control onChange={textChangeHandler} onClick={hide} value={enteredText} type="text" placeholder="Enter Goal" />
    <Form.Text className="text-muted">
      Enter your goal here.
    </Form.Text>
  </Form.Group> 
  
   <Button variant="primary" type="submit">
    Submit
  </Button>
</Form>
        </div>
    )
}

export default NewGoal
