import React from "react";
import { Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import "./UserTable.css";

const UserTable = (props) => {
	return (
		<div>
			<Table striped bordered hover>
				<thead>
					<tr key={0}>
						<th> # </th>
						<th> First Name</th>
						<th> Last Name </th>
						<th> Email Address</th>
					</tr>
				</thead>
				<tbody>
					{props.users.map((user, i) => {
						return (
							<LinkContainer
								key={user.id}
								to={props.password ? `/system/backend/${user.id}/userInfoCDS_password` : `/system/backend/${user.id}/userInfoCDS`}>
								<tr>
									<td>{++i}</td>
									<td>{user.userFirstName}</td>
									<td>{user.userLastName}</td>
									<td>{user.userEmail}</td>
								</tr>
							</LinkContainer>
						);
					})}
				</tbody>
			</Table>
		</div>
	);
};

export default UserTable;
