import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import "./AdminSideBar.css";

const five = {
	marginRight: "5px",
};

function AdminSideBar() {
	return (
		<Container>
			<LinkContainer className='admin-link' to='/system/backend/'>
				<h2 className='panel-heading'>Admin Panel</h2>
			</LinkContainer>

			<LinkContainer to='/system/backend/adduser'>
				<Row className='admin-link'>
					<Col>
						<p>
							<i style={five} className='far fa-user admin-icon'></i>Add New User
						</p>
					</Col>
				</Row>
			</LinkContainer>

			<LinkContainer to='/system/backend/updateuser'>
				<Row className='admin-link'>
					<Col>
						<p>
							<i className='fas fa-user-edit admin-icon'></i>Edit User
						</p>
					</Col>
				</Row>
			</LinkContainer>

			<LinkContainer to='/system/backend/updateuser_passord'>
				<Row className='admin-link'>
					<Col>
						<p>
							<i className='fas fa-user-edit admin-icon'></i>Edit User Password
						</p>
					</Col>
				</Row>
			</LinkContainer>

			<LinkContainer to='/system/backend/updatesystem'>
				<Row className='admin-link'>
					<Col>
						<p>
							<i className='fas fa-server admin-icon'></i>Update System
						</p>
					</Col>
				</Row>
			</LinkContainer>
		</Container>
	);
}

export default AdminSideBar;
