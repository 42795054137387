import React, { useState, useContext } from "react";
import { parse } from "papaparse";
import { Spinner } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { AuthContext } from "../../shared/context/auth-context";

import "./SystemUpload2.css";

function SystemUpload2(props) {
	const [highlighted, setHighlighted] = useState(false);
	const [systems, setSystems] = useState([]);
	const auth = useContext(AuthContext);

	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	const uploadSystem = (e) => {
		e.preventDefault();
		setHighlighted(false);
		Array.from(e.dataTransfer.files)
			.filter((file) => file.type === "text/csv")
			.forEach(async (file) => {
				const text = await file.text();
				const result = parse(text, { header: true });
				try {
					const responseData = await sendRequest(
						`${process.env.REACT_APP_BACKEND_URL}/api/systems/insert_many`,
						"POST",
						JSON.stringify({
							newSystems: result.data,
						}),
						{
							"Content-Type": "application/json",
							Authorization: "Bearer " + auth.token,
						}
					);

					setSystems(() => [...responseData.system]);
					let filteredSystemListNew = [];
					props.updateResults(filteredSystemListNew);
				} catch (err) {}
			});
	};

	return (
		<>
			<h1 className='import-Heading'> Import Systems from CSV File </h1>

			<div className='container'>
				<p> Draging the file will automatically upload the systems </p>
				<p>Added systems will be listed below.</p>
				<ErrorMessageModel
					show={errorModalShow}
					onHide={() => {
						setErrorModalShow(false);
					}}
					message={error}
				/>

				{isLoading && (
					<div className='spinner-loading'>
						<Spinner animation='border' variant='primary' /> <span>Loading...</span>
					</div>
				)}

				<div
					className={`upload-center ${highlighted ? "border-green-600 bg-green-100" : "border-gray-600"} `}
					onDragEnter={() => {
						setHighlighted(true);
					}}
					onDragLeave={() => {
						setHighlighted(false);
					}}
					onDragOver={(e) => {
						e.preventDefault();
					}}
					onDrop={uploadSystem}>
					{" "}
					<div className='centered-text'> Drop CSV file Here</div>
				</div>

				<h3>Systems Added</h3>
				<ul>
					{systems.map((system) => (
						<li key={system.serialNum}>
							<strong>{system.customerName} </strong>: {system.serialNum}
						</li>
					))}
				</ul>
			</div>
		</>
	);
}

export default SystemUpload2;
