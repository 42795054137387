import React from 'react'

const NewLineText = (props) => {
const text = props.text;
const newText = text.split('\n').map((str, i) => <span key={i}>{str} <br></br> </span>)

    return   (
         newText 
        )
    
}

export default NewLineText
