import React from "react";
import { Col, Row, Container, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import "./AdminHomePage.css";

function AdminHomePage() {
	return (
		<div>
			<h1 className='admin-heading'>Admin Home Page</h1>
			<Container fluid='md'>
				<Row>
					<Col lg>
						<LinkContainer to='/system/backend/adduser'>
							<Card key={1} text='dark' className='mb-5 admin-card-shadow'>
								<Card.Header>Create New User</Card.Header>
								<Card.Body>
									<Card.Title> Create User </Card.Title>
									<Card.Text>Here we can create new users. This is for CDS personel only</Card.Text>
								</Card.Body>
							</Card>
						</LinkContainer>
					</Col>
					<Col lg>
						<LinkContainer to='/system/backend/updateuser'>
							<Card key={2} text='dark' className='mb-5 admin-card-shadow'>
								<Card.Header>Update User</Card.Header>
								<Card.Body>
									<Card.Title> Update User </Card.Title>
									<Card.Text>update user profiles</Card.Text>
								</Card.Body>
							</Card>
						</LinkContainer>
					</Col>
					<Col lg>
						<LinkContainer to='/system/backend/updateuser_passord'>
							<Card key={1} text='dark' className='mb-5 admin-card-shadow'>
								<Card.Header>Update User Password</Card.Header>
								<Card.Body>
									<Card.Title> Update User Password </Card.Title>
									<Card.Text>Reset users password </Card.Text>
								</Card.Body>
							</Card>
						</LinkContainer>
					</Col>
				</Row>
				<Row>
					<Col lg={4}>
						<LinkContainer to='/system/backend/updatesystem'>
							<Card key={3} text='dark' className='mb-5 admin-card-shadow'>
								<Card.Header>Update System</Card.Header>
								<Card.Body>
									<Card.Title> Update System</Card.Title>
									<Card.Text>With this option you are able to update the serial number of a system.</Card.Text>
								</Card.Body>
							</Card>
						</LinkContainer>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default AdminHomePage;
