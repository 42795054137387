import { useState } from 'react'



const useVisibilityToggler = (conponent, visibility = false) => {
    const [visible, setVisibility ] = useState( () => visibility );



    return [ visible ? conponent : null, () => setVisibility(true),() => setVisibility(false)];
    
}

export default useVisibilityToggler
