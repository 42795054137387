import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
	const [userId, setUserId] = useState();
	const [userEmail, setUserEmail] = useState();
	const [admin, setAdmin] = useState();
	const [tokenExpirationDate, setTokenExpirationDate] = useState();
	const [token, setToken] = useState(false);

	const login = useCallback((uid, token, userEmail, admin, expirationDate) => {
		setToken(token);
		const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 2);
		setTokenExpirationDate(tokenExpirationDate);
		localStorage.setItem(
			"userData",
			JSON.stringify({ userId: uid, token: token, userEmail: userEmail, admin: admin, expiration: tokenExpirationDate.toISOString() })
		);
		setUserId(uid);
		setUserEmail(userEmail);
		setAdmin(admin);
	}, []);

	const logout = useCallback(() => {
		setToken(null);
		setUserId(null);
		setUserEmail(null);
		setAdmin(null);
		setTokenExpirationDate(null);
		localStorage.removeItem("userData");
	}, []);

	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [token, logout, tokenExpirationDate]);

	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem("userData"));
		if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
			login(storedData.userId, storedData.token, storedData.userEmail, storedData.admin, new Date(storedData.expiration));
		}
	}, [login]);

	return { token, userId, admin, userEmail, login, logout };
};
