import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Jumbotron, Container, Col, Breadcrumb, Form, FormControl, Button, Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { AuthContext } from "../../shared/context/auth-context";

import "./EditSystem.css";

const EditSystem = (props) => {
	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();
	const auth = useContext(AuthContext);

	const sysId = useParams().sysId;
	const [system, setSystem] = useState();

	const [editCustomerName, setEditCustomerName] = useState("");
	const [editSerialNumber, setEditSerialNumber] = useState("");
	const [editAddress, setEditAddress] = useState("");
	const [editSystemAdminName, setEditSystemAdminName] = useState("");
	const [editSystemAdminEmail, setEditSystemAdminEmail] = useState("");
	const [editSystemAdminPhone, setEditSystemAdminPhone] = useState("");
	const [editCity, setEditCity] = useState("");
	const [editState, setEditState] = useState("");
	const [editZip, setEditZip] = useState("");
	const [editCountry, setEditCountry] = useState("");
	const [editSystemType, setEditSystemType] = useState("");
	const [editContactForLogs, setEditContactForLogs] = useState("");
	const [editContactEmailForLogs, setEditContactEmailForLogs] = useState("");
	const [editContactPhoneForLogs, setEditContactPhoneForLogs] = useState("");
	const [editShippingAddress, setEditShippingAddress] = useState("");
	const [editLabor, setEditLabor] = useState("");
	const [editLaborContactEmail, setEditLaborContactEmail] = useState("");
	const [editLaborContactPhone, setEditLaborContactPhone] = useState("");
	const [editNotes, setEditNotes] = useState("");
	const [editFtp, setEditFtp] = useState("");

	useEffect(() => {
		const getSystem = async () => {
			let cleanSystemIdString = encodeURIComponent(sysId.trim());
			try {
				const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/systems/system/${cleanSystemIdString}`, "GET", null, {
					Authorization: "Bearer " + auth.token,
				});
				setSystem(responseData.system);
				setEditCustomerName(responseData.system.customerName);
				setEditSerialNumber(responseData.system.serialNum);
				setEditAddress(responseData.system.address);
				setEditSystemAdminName(responseData.system.systemAdminName);
				setEditSystemAdminEmail(responseData.system.systemAdminEmail);
				setEditSystemAdminPhone(responseData.system.systemAdminPhone);
				setEditCity(responseData.system.city);
				setEditState(responseData.system.state);
				setEditZip(responseData.system.zip);
				setEditCountry(responseData.system.country);
				setEditSystemType(responseData.system.systemType);
				setEditContactForLogs(responseData.system.contactForLogs);
				setEditContactEmailForLogs(responseData.system.contactEmailForLogs);
				setEditContactPhoneForLogs(responseData.system.contactPhoneForLogs);
				setEditShippingAddress(responseData.system.shippingAddress);
				setEditLabor(responseData.system.labor);
				setEditLaborContactEmail(responseData.system.laborContactEmail);
				setEditLaborContactPhone(responseData.system.laborContactPhone);
				setEditNotes(responseData.system.notes);
				setEditFtp(responseData.system.ftp);
			} catch (err) {}
		};
		getSystem();
	}, [sendRequest, sysId, auth.token]);

	let systemFound = false;
	let adminpage = true;
	let history = useHistory();
	const address = useLocation();

	if (system) {
		systemFound = true;
	} else {
		setSystem({});
	}

	if (address.pathname === `/${sysId}/editsystem`) {
		adminpage = false;
	}

	const deleteSystemHandler = () => {
		const deleteSystem = {
			id: system.id,
			customerName: editCustomerName,
			serialNum: editSerialNumber,
			address: editAddress,
			systemAdminName: editSystemAdminName,
			systemAdminEmail: editSystemAdminEmail,
			systemAdminPhone: editSystemAdminPhone,
			city: editCity,
			state: editState,
			zip: editZip,
			country: editCountry,
			systemType: editSystemType,
			contactForLogs: editContactForLogs,
			contactEmailForLogs: editContactEmailForLogs,
			contactPhoneForLogs: editContactPhoneForLogs,
			shippingAddress: editShippingAddress,
			labor: editLabor,
			laborContactEmail: editLaborContactEmail,
			laborContactPhone: editLaborContactPhone,
			notes: editNotes,
			ftp: editFtp,
		};

		props.setSystemToDelete((system) => {
			return (system = deleteSystem);
		});

		props.showDeleteModal(true);
	};

	const editSystemHandler = async (event) => {
		event.preventDefault();

		const editSystem = {
			id: system.id,
			creator: system.creator,
			customerName: editCustomerName,
			serialNum: editSerialNumber,
			address: editAddress,
			systemAdminName: editSystemAdminName,
			systemAdminEmail: editSystemAdminEmail,
			systemAdminPhone: editSystemAdminPhone,
			city: editCity,
			state: editState,
			zip: editZip,
			country: editCountry,
			systemType: editSystemType,
			contactForLogs: editContactForLogs,
			contactEmailForLogs: editContactEmailForLogs,
			contactPhoneForLogs: editContactPhoneForLogs,
			shippingAddress: editShippingAddress,
			labor: editLabor,
			laborContactEmail: editLaborContactEmail,
			laborContactPhone: editLaborContactPhone,
			notes: editNotes,
			ftp: editFtp,
		};

		try {
			await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/systems/${editSystem.id}`, "PATCH", JSON.stringify(editSystem), {
				"Content-Type": "application/json",
				Authorization: "Bearer " + auth.token,
			});
			let filteredSystemListNew = [];
			props.showModal(true);
			props.updateResults(filteredSystemListNew);
			history.push();
		} catch (err) {}
	};

	const customerNameChangeHandler = (event) => {
		setEditCustomerName(event.target.value);
	};

	const serialNumberChangeHandler = (event) => {
		setEditSerialNumber(event.target.value);
	};

	const serialNumberNoChangeHandler = (event) => {};

	const systemAdminNameChangeHandler = (event) => {
		setEditSystemAdminName(event.target.value);
	};

	const systemAdminEmailChangeHandler = (event) => {
		setEditSystemAdminEmail(event.target.value);
	};

	const systemAdminPhoneChangeHandler = (event) => {
		setEditSystemAdminPhone(event.target.value);
	};

	const addresChangeHandler = (event) => {
		setEditAddress(event.target.value);
	};

	const cityChangeHandler = (event) => {
		setEditCity(event.target.value);
	};

	const stateChangeHandler = (event) => {
		setEditState(event.target.value);
	};

	const zipChangeHandler = (event) => {
		setEditZip(event.target.value);
	};

	const countryChangeHandler = (event) => {
		setEditCountry(event.target.value);
	};

	const systemTypeChangeHandler = (event) => {
		setEditSystemType(event.target.value);
	};

	const contactForLogsChangeHandler = (event) => {
		setEditContactForLogs(event.target.value);
	};

	const contactEmailForlogsChangeHandler = (event) => {
		setEditContactEmailForLogs(event.target.value);
	};

	const contactPhoneForlogsChangeHandler = (event) => {
		setEditContactPhoneForLogs(event.target.value);
	};

	const laborChangeHandler = (event) => {
		setEditLabor(event.target.value);
	};

	const laborContactEmailChangeHandler = (event) => {
		setEditLaborContactEmail(event.target.value);
	};

	const laborContactPhoneChangeHandler = (event) => {
		setEditLaborContactPhone(event.target.value);
	};

	const shippingAddressChangeHandler = (event) => {
		setEditShippingAddress(event.target.value);
	};

	const notesChangeHandler = (event) => {
		setEditNotes(event.target.value);
	};

	const ftpChangeHandler = (event) => {
		setEditFtp(event.target.value);
	};

	if (systemFound === false) {
		return (
			<>
				<Container>
					<h2> System not found </h2>
					<ErrorMessageModel
						show={errorModalShow}
						onHide={() => {
							setErrorModalShow(false);
						}}
						message={error}
					/>

					{isLoading && (
						<div className='spinner-loading'>
							<Spinner animation='border' variant='primary' /> <span>Loading...</span>
						</div>
					)}
					<Breadcrumb>
						<LinkContainer to={address.pathname === `/${sysId}/editsystem` ? `/system/search` : `/system/backend/updatesystem`}>
							<Breadcrumb.Item>Search</Breadcrumb.Item>
						</LinkContainer>
						<Breadcrumb.Item active>System Edit</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</>
		);
	}

	return (
		<div>
			<Jumbotron className='bg-secondary text-white' fluid>
				<h1 className='headerSysInfo'>Update system</h1>
				<h1 className='headerSysInfo'>{editCustomerName}</h1>
				<h2 className='headerSysInfo'>{editSerialNumber}</h2>
				<p className='paragraphCenter'>
					Edit system. {address.pathname === `/${sysId}/editsystem` ? "Serial number can NOT be changed." : "Serial Number Can Be Edited"}
				</p>
				<p className='paragraphCenter'>
					<LinkContainer
						to={address.pathname === `/${sysId}/editsystem` ? `/${system.id}/systemInfoCDS` : `/system/backend/${system.id}/systemInfoCDSadmin`}>
						<Button variant='secondary'>Return to System</Button>
					</LinkContainer>
				</p>
			</Jumbotron>

			<Container>
				<ErrorMessageModel
					show={errorModalShow}
					onHide={() => {
						setErrorModalShow(false);
					}}
					message={error}
				/>

				{isLoading && (
					<div className='spinner-loading'>
						<Spinner animation='border' variant='primary' /> <span>Loading...</span>
					</div>
				)}

				<div className='button-container'>
					{adminpage ? (
						<Button onClick={deleteSystemHandler} className='del-button' variant='danger'>
							Delete
						</Button>
					) : (
						<div></div>
					)}
				</div>

				<Form onSubmit={editSystemHandler} autoComplete='off'>
					<Form.Row>
						<Form.Group as={Col} md controlId='formGridCustomerName'>
							<Form.Label>Customer Name</Form.Label>
							<Form.Control onChange={customerNameChangeHandler} value={editCustomerName} type='text' placeholder='Customer Name' required />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridSerialNumber'>
							<Form.Label>Serial Number</Form.Label>
							<Form.Control
								onChange={address.pathname === `/${sysId}/editsystem` ? serialNumberNoChangeHandler : serialNumberChangeHandler}
								value={editSerialNumber}
								type='text'
								placeholder='Enter Serial Number'
								disabled={address.pathname === `/${sysId}/editsystem` ? true : false}
								required
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md controlId='formGridSystemAdminName'>
							<Form.Label>System Admin Name </Form.Label>
							<Form.Control onChange={systemAdminNameChangeHandler} value={editSystemAdminName} type='text' placeholder='Name' />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridSystemAdminEmail'>
							<Form.Label>System Admin Email </Form.Label>
							<Form.Control onChange={systemAdminEmailChangeHandler} value={editSystemAdminEmail} type='email' placeholder='Enter Email' />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridSystemAdminPhone'>
							<Form.Label>System Admin phone number </Form.Label>
							<Form.Control onChange={systemAdminPhoneChangeHandler} value={editSystemAdminPhone} placeholder='Enter Phone 312-555-1234' />
						</Form.Group>
					</Form.Row>

					<Form.Group controlId='formGridAddress1'>
						<Form.Label>Address</Form.Label>
						<Form.Control onChange={addresChangeHandler} value={editAddress} placeholder='1234 Main St' />
					</Form.Group>

					<Form.Row>
						<Form.Group as={Col} sm controlId='formGridCity'>
							<Form.Label>City</Form.Label>
							<Form.Control onChange={cityChangeHandler} value={editCity} placeholder='City' />
						</Form.Group>

						<Form.Group as={Col} sm controlId='formGridState'>
							<Form.Label>State</Form.Label>
							<Form.Control onChange={stateChangeHandler} value={editState} />
						</Form.Group>

						<Form.Group as={Col} sm controlId='formGridZip'>
							<Form.Label>Zip</Form.Label>
							<Form.Control onChange={zipChangeHandler} value={editZip} />
						</Form.Group>

						<Form.Group as={Col} sm controlId='formGridCountry'>
							<Form.Label>Country</Form.Label>
							<Form.Control onChange={countryChangeHandler} value={editCountry} />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md controlId='formGridSystemType'>
							<Form.Label>System Type </Form.Label>
							<Form.Control onChange={systemTypeChangeHandler} value={editSystemType} type='text' placeholder='Enter System Type and model' />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridContactLog'>
							<Form.Label>Contact For logs </Form.Label>
							<Form.Control onChange={contactForLogsChangeHandler} value={editContactForLogs} type='text' placeholder='Enter Contact Name' />
							<Form.Label>Contact Phone # For Logs</Form.Label>
							<Form.Control
								onChange={contactPhoneForlogsChangeHandler}
								value={editContactPhoneForLogs}
								type='text'
								placeholder='Enter Contact Phone #'
							/>
							<Form.Label>Contact Email for logs</Form.Label>
							<Form.Control onChange={contactEmailForlogsChangeHandler} value={editContactEmailForLogs} type='email' placeholder='name@example.com' />
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} md controlId='formGridLabor'>
							<Form.Label>Larbor </Form.Label>
							<Form.Control onChange={laborChangeHandler} value={editLabor} type='text' placeholder='Enter who does the labor (i.e. IBM or UTC)' />
							<Form.Label> Labor Email</Form.Label>
							<FormControl
								onChange={laborContactEmailChangeHandler}
								value={editLaborContactEmail}
								type='text'
								placeholder='Enter Labor Email address'
							/>
							<Form.Label> Labor Phone</Form.Label>
							<FormControl onChange={laborContactPhoneChangeHandler} value={editLaborContactPhone} type='tel' placeholder='Example: 432-555-3689' />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridShippingAddress'>
							<Form.Label>Shipping Address </Form.Label>
							<Form.Control
								onChange={shippingAddressChangeHandler}
								value={editShippingAddress}
								as='textarea'
								rows={4}
								placeholder='Shipping Address for system'
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md controlId='formGridNotes'>
							<Form.Label>Enter Notes</Form.Label>
							<Form.Control onChange={notesChangeHandler} value={editNotes} as='textarea' rows={4} />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridFtp'>
							<Form.Label>Enter FTP info </Form.Label>
							<Form.Control onChange={ftpChangeHandler} value={editFtp} as='textarea' rows={4} />
						</Form.Group>
					</Form.Row>

					<Button variant='primary' type='submit'>
						Submit
					</Button>
					<LinkContainer
						to={address.pathname === `/${sysId}/editsystem` ? `/${system.id}/systemInfoCDS` : `/system/backend/${system.id}/systemInfoCDSadmin`}>
						<Button variant='dark' className='mx-2'>
							{" "}
							Cancel
						</Button>
					</LinkContainer>
				</Form>
			</Container>
		</div>
	);
};

export default EditSystem;
