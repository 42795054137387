import React, { useState } from "react";
import { Button, Container, Form, Spinner, Alert } from "react-bootstrap";

import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";
import { useHttpClient } from "../../hooks/http-hook";

import "./ResendVerifyEmail.css";

const ResendVerifyEmail = () => {
	const [enteredUserEmail, setEnteredUserEmail] = useState("");
	const [serverRes, setServerRes] = useState("");
	const { isLoading, error, errorModalShow, setError, setErrorModalShow, sendRequest } = useHttpClient();
	const [requestComplete, setRequestComplete] = useState(false);

	const resendEmail = async (event) => {
		event.preventDefault();

		try {
			const responseData = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/api/users/verify/resendemail`,
				"POST",
				JSON.stringify({
					userEmail: enteredUserEmail,
				}),

				{
					"Content-Type": "application/json",
				}
			);
			console.log(responseData);
			setServerRes(responseData);
			setRequestComplete(true);
			setError(null);
		} catch (err) {}
	};

	const userEmailChangeHandler = (event) => {
		setEnteredUserEmail(event.target.value);
	};

	return (
		<>
			<Container>
				<div className='p-5 mb-4 bg-light rounded-3'>
					<div className='container-fluid py-5'>
						<ErrorMessageModel
							show={errorModalShow}
							onHide={() => {
								setErrorModalShow(false);
							}}
							message={error}
						/>

						<h1 className='display-5 fw-bold'>Resend Verification Email</h1>
						{isLoading && (
							<div className='spinner-loading'>
								<Spinner animation='border' variant='primary' /> <span>Sending...</span>
							</div>
						)}
						{error && <Alert variant='danger'> {error} </Alert>}
						{!requestComplete && (
							<Form onSubmit={resendEmail} autoComplete='off'>
								<Form.Group className='mb-3' controlId='formBasicEmail'>
									<Form.Label>Email address</Form.Label>
									<Form.Control onChange={userEmailChangeHandler} value={enteredUserEmail} type='email' placeholder='Enter email' required />
								</Form.Group>
								<Button variant='primary' type='submit'>
									Submit
								</Button>
							</Form>
						)}
						{requestComplete && (
							<>
								<div className='card request-card text-center mx-auto mt-5 bg-success mb-1'>
									<div className='card-header'>Email Sent </div>
									<div className='card-body'>
										<h5 className='card-title text-light text-center'>Success</h5>
										<p className='card-text text-light'>{serverRes} </p>
									</div>
								</div>
								<div className='col-3 mx-auto'>
									<Button href='/login' className='mt-4 '>
										Return To Login
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			</Container>
		</>
	);
};

export default ResendVerifyEmail;
