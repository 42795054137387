import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import Header from "./component/Header";
import Footer from "./component/Footer";

import GoalList from "./component/GoalList/GoalList";
import SystemTable from "./component/SystemTable/SystemTable";
import NewSystem from "./component/NewSystem/NewSystem";
import NewGoal from "./component/NewGoal/NewGoal";

import SearchSystem from "./component/SearchSystem/SearchSystem";
import Search from "./systems/pages/Search";

import Login from "./component/Login/Login";
import SystemInfoCDS from "./systems/pages/SystemInfoCDS";
import EditSystem from "./systems/pages/EditSystem";

import ScrollToTop from "./ScrollToTop";
import MessageUpdate from "./shared/messageUpdate/MessageUpdate";
import MessageUserUpdate from "./shared/messageUpdate/MessageUserUpdate";
import DeleteValidation from "./shared/deleteValidation/DeleteValidation";
import DeleteUserValidation from "./shared/deleteValidation/DeleteUserValidation";

import AdminSideBar from "./systems/components/AdminSideBar";
import AdminHomePage from "./systems/pages/AdminHomePage";
import AdminAddUser from "./systems/pages/AdminAddUser";

import SearchUser from "./component/SearchUser/SearchUser";
import UserTable from "./component/UserTable/UserTable";
import EditUser from "./systems/pages/EditUser";
import EditUserPassword from "./systems/pages/EditUserPassword";

import SystemUpload2 from "./systems/pages/SystemUpload2";

import VerifyEmail from "./systems/pages/VerifyEmail";
import ResendVerifyEmail from "./systems/pages/ResendVerifyEmail";

import { AuthContext } from "./shared/context/auth-context";

import { useAuth } from "./hooks/auth-hook";

import "./App.css";

const App = () => {
	// This is an example array of objest for setting goals app
	const [courseGoals, setcourseGoals] = useState([
		{ id: "cg1", text: "Finsh the Course" },
		{ id: "cg2", text: "Learn all about the Course Main Topic" },
		{ id: "cg3", text: "Help other students in the Course Q&A" },
	]);

	const { token, userId, admin, userEmail, login, logout } = useAuth();

	const [searchResultsList, setSearchResultsList] = useState([]);
	const [searchUserResultsList, setSearchUserResultsList] = useState([]);
	const [modalShow, setModalShow] = React.useState(false);
	const [showModalSystemDelete, setShowModalSystemDelete] = useState(false);
	const [systemToDelete, setSystemToDelete] = useState({});
	const [userToDelete, setUserToDelete] = useState({});
	const [showModaleUserDelete, setShowModalUserDelete] = useState(false);

	const addNewGoalHandler = (newGoal) => {
		// setcourseGoals(courseGoals.concat(newGoal));
		setcourseGoals((prevCourseGoals) => {
			return prevCourseGoals.concat(newGoal);
		});
	};

	const setModelOnHandler = (value) => {
		setModalShow((mod) => {
			return (mod = value);
		});
	};

	const setModalSystemDeleteOnHandler = (value) => {
		setShowModalSystemDelete((mod) => {
			return (mod = value);
		});
	};

	const setModelUserDeleteOnHandler = (value) => {
		setShowModalUserDelete((mod) => {
			return (mod = value);
		});
	};
	const addSearchResultsListHandler = (filterSystemList) => {
		setSearchResultsList((newSearchResultsList) => {
			return (newSearchResultsList = filterSystemList);
		});
	};

	const addUserSearchResultsListHandler = (filterUserList) => {
		setSearchUserResultsList((newSearchResultsUserList) => {
			return (newSearchResultsUserList = filterUserList);
		});
	};

	let routes;

	if (token) {
		routes = (
			<Switch>
				<Route path='/system/search' exact>
					<main>
						<Container>
							<Search />
							<SearchSystem updateResults={addSearchResultsListHandler} />
							<SystemTable systems={searchResultsList} />
						</Container>
					</main>
				</Route>

				{admin && (
					<Route path='/system/backend'>
						<main>
							<>
								<Container fluid className='admin_panel_no_left_margin'>
									<Row>
										<Col sm={3} className='admin-panel-background'>
											<AdminSideBar />
										</Col>
										<Col sm={9}>
											<Route path='/system/backend' exact>
												<AdminHomePage />
											</Route>
											<Route path='/system/backend/adduser' exact>
												<AdminAddUser showModal={setModelOnHandler} />
												<MessageUserUpdate show={modalShow} onHide={() => setModalShow(false)} />
											</Route>
											<Route path='/system/backend/updateuser' exact>
												<h1 className='edit-user-header'> Edit User</h1>
												<SearchUser updateUserResult={addUserSearchResultsListHandler} />
												<UserTable users={searchUserResultsList} />
											</Route>
											<Route path='/system/backend/updateuser_passord' exact>
												<h1 className='edit-user-header'> Edit User Password</h1>
												<SearchUser updateUserResult={addUserSearchResultsListHandler} />
												<UserTable password={true} users={searchUserResultsList} />
											</Route>
											<Route path='/system/backend/updatesystem' exact>
												<h1>System Update system</h1>
												<SearchSystem updateResults={addSearchResultsListHandler} />
												<SystemTable systems={searchResultsList} />
											</Route>
											<Route path='/system/backend/:userId/userInfoCDS' exact>
												<EditUser
													setUserToDelete={setUserToDelete}
													showDeleteUserModal={setModelUserDeleteOnHandler}
													updateUserResult={addUserSearchResultsListHandler}
												/>
												<DeleteUserValidation
													show={showModaleUserDelete}
													onHide={() => setShowModalUserDelete(false)}
													userToDelete={userToDelete}
													updateUserResult={addUserSearchResultsListHandler}
												/>
											</Route>
											<Route path='/system/backend/:userId/userInfoCDS_password' exact>
												<EditUserPassword
													setUserToDelete={setUserToDelete}
													showDeleteUserModal={setModelUserDeleteOnHandler}
													updateUserResult={addUserSearchResultsListHandler}
												/>
												<DeleteUserValidation
													show={showModaleUserDelete}
													onHide={() => setShowModalUserDelete(false)}
													userToDelete={userToDelete}
													updateUserResult={addUserSearchResultsListHandler}
												/>
											</Route>

											<Route path='/system/backend/:sysId/systemInfoCDSadmin' exact>
												<SystemInfoCDS />
											</Route>

											<Route path='/system/backend/:sysId/editsystem' exact>
												<EditSystem
													showModal={setModelOnHandler}
													showDeleteModal={setModalSystemDeleteOnHandler}
													setSystemToDelete={setSystemToDelete}
												/>
												<MessageUpdate show={modalShow} onHide={() => setModalShow(false)} />
												<DeleteValidation
													show={showModalSystemDelete}
													onHide={() => setShowModalSystemDelete(false)}
													systemToDelete={systemToDelete}
													updateResults={addSearchResultsListHandler}
												/>
											</Route>
										</Col>
									</Row>
								</Container>
							</>
						</main>
					</Route>
				)}
				<Route path='/system/add' exact>
					<Container>
						<h1>Add new system Info</h1>
						<h2 className='text-center newSystemHeader'> Enter New System</h2>
						<NewSystem showModal={setModelOnHandler} />
						<MessageUpdate show={modalShow} onHide={() => setModalShow(false)} />
					</Container>
				</Route>

				<Route path='/system/upload' exact>
					<main>
						<Container>
							<SystemUpload2 updateResults={addSearchResultsListHandler} />
						</Container>
					</main>
				</Route>

				<Route path='/goal' exact>
					<Container>
						<div className='course-goals'>
							<h3> Course Goals</h3>
							<NewGoal onAddGoal={addNewGoalHandler} />
							<GoalList goals={courseGoals} />
						</div>
					</Container>
				</Route>

				<Route path='/:sysId/systemInfoCDS' exact>
					<SystemInfoCDS />
				</Route>

				<Route path='/:sysId/editsystem' exact>
					<EditSystem showModal={setModelOnHandler} updateResults={addSearchResultsListHandler} />
					<MessageUpdate show={modalShow} onHide={() => setModalShow(false)} />
				</Route>

				<Redirect to='/system/search' />
			</Switch>
		);
	} else {
		routes = (
			<Switch>
				<Route path='/login' exact>
					<main className='py-3'>
						<Container>
							<h1> Welcome to CDS Info</h1>

							<Login />
						</Container>
					</main>
				</Route>
				<Route path='/verify/email/:emailVerifyString' exact>
					<VerifyEmail />
				</Route>
				<Route path='/verify/resend/email/' exact>
					<ResendVerifyEmail />
				</Route>
				<Redirect to='/login' />
			</Switch>
		);
	}

	return (
		<AuthContext.Provider
			value={{ isLoggedIn: !!token, token: token, userId: userId, userEmail: userEmail, admin: admin, login: login, logout: logout }}>
			<Router>
				<>
					<Header />
					<ScrollToTop>{routes}</ScrollToTop>
					<Footer />
				</>
			</Router>
		</AuthContext.Provider>
	);
};

export default App;
