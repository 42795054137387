import React, { useContext } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../messageUpdate/ErrorMessageModel";

import { AuthContext } from "../context/auth-context";

import "./DeleteUserValidation.css";

function DeleteUserValidation(props) {
	const auth = useContext(AuthContext);

	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	const clearSearchResualts = () => {
		let filteredUserListNew = [];
		props.updateUserResult(filteredUserListNew);
	};

	return (
		<>
			<ErrorMessageModel
				show={errorModalShow}
				onHide={() => {
					setErrorModalShow(false);
				}}
				message={error}
			/>

			<Modal {...props} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Delete User</Modal.Title>
					{isLoading && (
						<div className='spinner-loading'>
							<Spinner animation='border' variant='primary' /> <span>Deleting...</span>
						</div>
					)}
				</Modal.Header>
				<Modal.Body>Are you sure you would like to delete this user? The action can not be undone.</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={cancelDelete}>
						Cancel
					</Button>
					<Button variant='danger' onClick={deleteUser}>
						Delete User
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);

	function cancelDelete() {
		props.onHide();
	}

	async function deleteUser() {
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/api/users/delete`,
				"DELETE",
				JSON.stringify({
					id: props.userToDelete.uid,
				}),
				{
					"Content-Type": "application/json",
					Authorization: "Bearer " + auth.token,
				}
			);
			clearSearchResualts();
			window.history.go(-1);
			props.onHide();
		} catch (err) {}
	}
}

export default DeleteUserValidation;
