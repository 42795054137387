import React, { useContext } from "react";

import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
//import { Link } from 'react-router-dom'

import { AuthContext } from "../shared/context/auth-context";

import "./Header.css";

// <LinkContainer to="/goal"><Nav.Link><i className='fas fa-bullseye'></i> Goal </Nav.Link></LinkContainer>
const Header = () => {
	const auth = useContext(AuthContext);

	return (
		<header>
			<Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
				<Container>
					<LinkContainer to={auth.isLoggedIn ? "/system/search" : "/login"}>
						<Navbar.Brand>CDS Site Info</Navbar.Brand>
					</LinkContainer>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ml-auto'>
							{!auth.isLoggedIn && (
								<LinkContainer to='/login' exact>
									<Nav.Link>
										<i className='fas fa-home'></i> Login
									</Nav.Link>
								</LinkContainer>
							)}
							{auth.isLoggedIn && (
								<LinkContainer to='/system/search'>
									<Nav.Link>
										<i className='fas fa-search'></i> Search
									</Nav.Link>
								</LinkContainer>
							)}
							{auth.isLoggedIn && (
								<LinkContainer to='/system/add'>
									<Nav.Link>
										<i className='fas fa-server'></i> New System
									</Nav.Link>
								</LinkContainer>
							)}
							{auth.isLoggedIn && (
								<LinkContainer to='/system/upload'>
									<Nav.Link>
										<i className='fas fa-upload'></i> Upload System
									</Nav.Link>
								</LinkContainer>
							)}

							{auth.isLoggedIn && auth.admin && (
								<LinkContainer to='/system/backend'>
									<Nav.Link>
										<i className='fas fa-user-shield'></i> Admin
									</Nav.Link>
								</LinkContainer>
							)}
							<Navbar.Text>
								{" "}
								<span className='pr-2 text-white'>{auth.userEmail}</span>
							</Navbar.Text>
							{auth.isLoggedIn && (
								<Button className='logout-button' onClick={auth.logout}>
									<LinkContainer to='/login'>
										<Nav.Link>
											<i className='fas fa-sign-out-alt'></i> LogOut
										</Nav.Link>
									</LinkContainer>
								</Button>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;
