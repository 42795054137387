import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Jumbotron, Button, Container, Form, Alert, InputGroup, Spinner } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";

import { AuthContext } from "../../shared/context/auth-context";

import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";
import MessageUserUpdate from "../../shared/messageUpdate/MessageUserUpdate";
import useVisibilityToggler from "../../hooks/useVisibilityToggler";

import "./EditUserPassword.css";

const EditUser = (props) => {
	const userId = useParams().userId;
	const auth = useContext(AuthContext);

	const [editUserEmail, setEditUserEmail] = useState("");
	const [editUserPassword, setEditUserPassword] = useState("");
	const [editUserConfirmPassword, setEditUserConfirmPassword] = useState("");
	const [showUserAdd, setShowUserAdd] = useState(false);

	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	const clearSearchResualts = () => {
		let filteredUserListNew = [];
		props.updateUserResult(filteredUserListNew);
	};

	//let currentUser = props.users.find((tempUsers) => tempUsers.uid === userId) ;
	let [currentUser, setCurrentUser] = useState();

	useEffect(() => {
		const getUser = async () => {
			try {
				let cleanUserId = encodeURIComponent(userId);
				const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/users/getuserbyid/${cleanUserId}`, "GET", null, {
					Authorization: "Bearer " + auth.token,
				});
				setCurrentUser(responseData.users);
				setEditUserEmail(responseData.users.userEmail);
			} catch (err) {}
		};
		getUser();
	}, [sendRequest, userId, auth.token]);

	let userFound = false;

	if (currentUser) {
		userFound = true;
	} else {
		currentUser = {};
	}

	const editUserFormHandler = async (event) => {
		event.preventDefault();
		const isPasswordSame = () => {
			return Boolean(editUserPassword === editUserConfirmPassword);
		};

		if (!isPasswordSame()) {
			showPasswordNotMatch();
			return;
		}

		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/api/users/update/userpassword`,
				"PATCH",
				JSON.stringify({
					userEmail: editUserEmail,
					userPassword: editUserPassword,
					userPasswordConfirm: editUserConfirmPassword,
				}),

				{
					"Content-Type": "application/json",
					Authorization: "Bearer " + auth.token,
				}
			);

			clearSearchResualts();
			hidePasswordNotMatch();
			setShowUserAdd(true);
		} catch (err) {}
	};

	const deleteUserHandler = () => {
		//alert("Delete user works");
		const deleteUser = {
			uid: currentUser.id,
			userFirstName: currentUser.userFirstName,
			userLastName: currentUser.userLastName,
			userEmail: currentUser.userEmail,
			userPassword: currentUser.userPassword,
			userPasswordConfirm: currentUser.userPasswordConfirm,
		};

		props.setUserToDelete((user) => {
			return (user = deleteUser);
		});

		props.showDeleteUserModal(true);
	};

	const userEmailChangeHandler = (event) => {
		setEditUserEmail(event.target.value);
	};
	const userPasswordChangeHandler = (event) => {
		setEditUserPassword(event.target.value);
	};
	const userConfirmPasswordChangeHandler = (event) => {
		setEditUserConfirmPassword(event.target.value);
	};

	function cancelUserEdit() {
		window.history.go(-1);
	}

	const passwordVisibility = () => {
		var passwordInput = document.getElementById("editPasswordInput");
		if (passwordInput.type === "password") {
			passwordInput.type = "text";
			showPasswordEye();
			hidePasswordEyeSlash();
		} else {
			passwordInput.type = "password";
			hidePasswordEye();
			showPasswordEyeSlash();
		}
	};

	const passwordConfirmVisibility = () => {
		var passwordConfirmInput = document.getElementById("EditPasswordConfirmInput");
		if (passwordConfirmInput.type === "password") {
			passwordConfirmInput.type = "text";
			showPasswordConfirmEye();
			hidePasswordConfirmEyeSlash();
		} else {
			passwordConfirmInput.type = "password";
			hidePasswordConfirmEye();
			showPasswordConfirmEyeSlash();
		}
	};

	const [alertPasswordNotMatch, showPasswordNotMatch, hidePasswordNotMatch] = useVisibilityToggler(
		<Alert variant='danger' onClose={() => hidePasswordNotMatch()} dismissible>
			Password did NOT match.
		</Alert>,
		false
	);

	const [seePasswordEye, showPasswordEye, hidePasswordEye] = useVisibilityToggler(<i className='far fa-eye'></i>, false);

	const [seePasswordEyeSlash, showPasswordEyeSlash, hidePasswordEyeSlash] = useVisibilityToggler(<i className='far fa-eye-slash'></i>, true);

	const [seePasswordConfirmEye, showPasswordConfirmEye, hidePasswordConfirmEye] = useVisibilityToggler(<i className='far fa-eye'></i>, false);

	const [seePasswordConfirmEyeSlash, showPasswordConfirmEyeSlash, hidePasswordConfirmEyeSlash] = useVisibilityToggler(
		<i className='far fa-eye-slash'></i>,
		true
	);

	if (userFound) {
		return (
			<div>
				<Container>
					<h1 className='edit-user-heading'>Edit User Password</h1>

					<ErrorMessageModel
						show={errorModalShow}
						onHide={() => {
							setErrorModalShow(false);
						}}
						message={error}
					/>

					<MessageUserUpdate show={showUserAdd} onHide={() => setShowUserAdd(false)} userId={userId} />

					<div className='button-container'>
						<Button onClick={deleteUserHandler} className='del-button' variant='danger'>
							Delete
						</Button>
					</div>
					{alertPasswordNotMatch}
					{isLoading && (
						<div className='spinner-loading'>
							<Spinner animation='border' variant='primary' /> <span>Updating...</span>
						</div>
					)}
					<Form onSubmit={editUserFormHandler} autoComplete='off'>
						<Form.Group>
							<Form.Label> Email </Form.Label>
							<Form.Control onChange={userEmailChangeHandler} value={editUserEmail} type='email' placeholder='Email' required disabled />
						</Form.Group>

						<Form.Group>
							<Form.Label> Password </Form.Label>
							<InputGroup className='mb-2'>
								<Form.Control
									onChange={userPasswordChangeHandler}
									id='editPasswordInput'
									value={editUserPassword}
									type='password'
									placeholder='Password'
									required></Form.Control>
								<InputGroup.Text className='eye-background' onClick={passwordVisibility}>
									<div>
										{seePasswordEye} {seePasswordEyeSlash}{" "}
									</div>{" "}
								</InputGroup.Text>
							</InputGroup>
						</Form.Group>

						<Form.Group>
							<Form.Label> Confirm Password </Form.Label>
							<InputGroup className='mb-2'>
								<Form.Control
									onChange={userConfirmPasswordChangeHandler}
									id='EditPasswordConfirmInput'
									value={editUserConfirmPassword}
									type='password'
									placeholder='Confirm Password'
									required></Form.Control>
								<InputGroup.Text className='eye-background' onClick={passwordConfirmVisibility}>
									<div>
										{seePasswordConfirmEye} {seePasswordConfirmEyeSlash}{" "}
									</div>{" "}
								</InputGroup.Text>
							</InputGroup>
						</Form.Group>

						<Button variant='primary' type='submit'>
							Submit
						</Button>
						<Button variant='dark' className='mx-3' onClick={cancelUserEdit}>
							{" "}
							Cancel{" "}
						</Button>
					</Form>
				</Container>
			</div>
		);
	} else {
		return (
			<div>
				<Container>
					<div className='edit-user-jumbo'>
						<ErrorMessageModel
							show={errorModalShow}
							onHide={() => {
								setErrorModalShow(false);
							}}
							message={error}
						/>

						{isLoading ? (
							<div className='spinner-loading'>
								<Spinner animation='border' variant='primary' /> <span>Loading...</span>
							</div>
						) : (
							<Jumbotron>
								<h1> User Not Found </h1>
								<p>The user you have entered is incorrect. Please return to the user edit page to continue.</p>
								<p>
									<Link to='/system/backend/updateuser'>
										<Button variant='primary'>Back to User Edit</Button>{" "}
									</Link>
								</p>
							</Jumbotron>
						)}
					</div>
				</Container>
			</div>
		);
	}
};

export default EditUser;
