import React from "react";
import { Table } from "react-bootstrap"
import './SystemTable.css'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'


const SystemTable = (props) => {

  let history = useLocation();
  return (  
    <div>
      <Table striped bordered hover>
        <thead>
          <tr  > 
            <th>#</th>
            <th>Customer Name</th>
            <th>Serial Number</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
         
           
          
          {
            props.systems.map( (system, i) =>{
              
              return <LinkContainer to={(history.pathname === "/system/backend/updatesystem") ? `/system/backend/${system.id}/systemInfoCDSadmin`: `/${system.id}/systemInfoCDS` } key={++i}  >
                <tr > 
                <td >{++i}</td>
                <td >{system.customerName}</td>
                <td >{system.serialNum}</td>
                <td >{system.address}</td>
              </tr> 
              </LinkContainer>;
            })}

        </tbody>
      </Table>
    </div>
  );
};

export default SystemTable;
