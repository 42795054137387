import React from 'react'
import {Modal, Button, } from 'react-bootstrap'

import {  useLocation, useHistory } from 'react-router-dom';


import './MessageUpdate.css'

function MessageUserUpdate(props) {
  const address = useLocation();

  const history = useHistory(); 

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           User Updated
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Your User has been {(address.pathname ===`/system/backend/${props.userId}/userInfoCDS` ) ? 'updated': 'entered' }</h4>
          <p>
            Success. 
          </p>
             
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        {(address.pathname ===`/system/backend/${props.userId}/userInfoCDS` ) ? <Button onClick={() =>{ history.push("/system/backend/updateuser") }}> Return to search </Button> : null }
        </Modal.Footer>
      </Modal>
    )
}  

export default MessageUserUpdate

