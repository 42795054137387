import React, { useState, useContext } from "react";
import { Container, Form, Button, Alert, InputGroup, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import useVisibilityToggler from "../../hooks/useVisibilityToggler";

import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { AuthContext } from "../../shared/context/auth-context";

import { useHttpClient } from "../../hooks/http-hook";

import "./AdminAddUser.css";

function AdminAddUser(props) {
	const auth = useContext(AuthContext);
	const history = useHistory();
	const [enteredFirstName, setEnteredFirstName] = useState("");
	const [enteredLastName, setEnteredLastName] = useState("");
	const [enteredUserEmail, setEnteredUserEmail] = useState("");
	const [enteredUserPassword, setEnteredUserPassword] = useState("");
	const [enteredUserPasswordConfirm, setEnteredUserPasswordConfirm] = useState("");
	const [enteredAdminIsChecked, setEnteredAdminIsChecked] = useState(false);

	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	const addNewUserHandler = async (event) => {
		event.preventDefault();

		const isPasswordSame = () => {
			return Boolean(enteredUserPassword === enteredUserPasswordConfirm);
		};

		if (!isPasswordSame()) {
			showPasswordNotMatch();
			return;
		}

		hidePasswordNotMatch();
		console.log(process.env.REACT_APP_BACKEND_URL);
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/api/users/signup/`,
				"POST",
				JSON.stringify({
					userFirstName: enteredFirstName,
					userLastName: enteredLastName,
					userEmail: enteredUserEmail.toLowerCase(),
					userPassword: enteredUserPassword,
					userPasswordConfirm: enteredUserPasswordConfirm,
					admin: enteredAdminIsChecked,
				}),
				{
					"Content-Type": "application/json",
					Authorization: "Bearer " + auth.token,
				}
			);

			setEnteredFirstName("");
			setEnteredLastName("");
			setEnteredUserEmail("");
			setEnteredUserPassword("");
			setEnteredUserPasswordConfirm("");
			setEnteredAdminIsChecked(false);

			props.showModal(true);
		} catch (err) {}
	};

	const firstNameChangeHandler = (event) => {
		setEnteredFirstName(event.target.value);
	};

	const lastNameChangeHandler = (event) => {
		setEnteredLastName(event.target.value);
	};

	const userEmailChangeHandler = (event) => {
		setEnteredUserEmail(event.target.value);
	};

	const userPasswordChangeHandler = (event) => {
		setEnteredUserPassword(event.target.value);
	};

	const userPasswordConfirmChangeHandler = (event) => {
		setEnteredUserPasswordConfirm(event.target.value);
	};

	const userAdminChangehandler = () => {
		setEnteredAdminIsChecked(!enteredAdminIsChecked);
	};

	function cancelUserCreate() {
		history.push("/system/backend/");
	}

	const passwordVisibility = () => {
		var passwordInput = document.getElementById("formBasicPassword");
		if (passwordInput.type === "password") {
			passwordInput.type = "text";
			showPasswordEye();
			hidePasswordEyeSlash();
		} else {
			passwordInput.type = "password";
			hidePasswordEye();
			showPasswordEyeSlash();
		}
	};

	const passwordConfirmVisibility = () => {
		var passwordConfirmInput = document.getElementById("formBasicPasswordConfirm");
		if (passwordConfirmInput.type === "password") {
			passwordConfirmInput.type = "text";
			showPasswordConfirmEye();
			hidePasswordConfirmEyeSlash();
		} else {
			passwordConfirmInput.type = "password";
			hidePasswordConfirmEye();
			showPasswordConfirmEyeSlash();
		}
	};

	const [alertPasswordNotMatch, showPasswordNotMatch, hidePasswordNotMatch] = useVisibilityToggler(
		<Alert variant='danger' onClose={() => hidePasswordNotMatch()} dismissible>
			Password did NOT match.
		</Alert>,
		false
	);

	const [seePasswordEye, showPasswordEye, hidePasswordEye] = useVisibilityToggler(<i className='far fa-eye'></i>, false);

	const [seePasswordEyeSlash, showPasswordEyeSlash, hidePasswordEyeSlash] = useVisibilityToggler(<i className='far fa-eye-slash'></i>, true);

	const [seePasswordConfirmEye, showPasswordConfirmEye, hidePasswordConfirmEye] = useVisibilityToggler(<i className='far fa-eye'></i>, false);

	const [seePasswordConfirmEyeSlash, showPasswordConfirmEyeSlash, hidePasswordConfirmEyeSlash] = useVisibilityToggler(
		<i className='far fa-eye-slash'></i>,
		true
	);

	return (
		<div>
			<Container>
				<h1 className='add-user-heading'>Add New User</h1>

				<ErrorMessageModel
					show={errorModalShow}
					onHide={() => {
						setErrorModalShow(false);
					}}
					message={error}
				/>

				<div className='add_user-form-container'>
					{isLoading && (
						<div className='spinner-loading'>
							<Spinner animation='border' variant='primary' /> <span>Loading...</span>
						</div>
					)}

					<Form onSubmit={addNewUserHandler} autoComplete='off'>
						<Form.Group controlId='formFirstName'>
							<div className='alertHight'> {alertPasswordNotMatch} </div>
							<Form.Label>First Name</Form.Label>
							<Form.Control onChange={firstNameChangeHandler} value={enteredFirstName} placeholder='First Name' required />
						</Form.Group>

						<Form.Group controlId='formLastName'>
							<Form.Label>Last Name</Form.Label>
							<Form.Control onChange={lastNameChangeHandler} value={enteredLastName} placeholder='Last Name' required />
						</Form.Group>

						<Form.Group controlId='formBasicEmail'>
							<Form.Label>Email address</Form.Label>
							<Form.Control onChange={userEmailChangeHandler} value={enteredUserEmail} type='email' placeholder='Enter email' required />
						</Form.Group>

						<Form.Group controlId='formBasicPassword'>
							<Form.Label>Password</Form.Label>
							<InputGroup className='mb-2'>
								<Form.Control onChange={userPasswordChangeHandler} value={enteredUserPassword} type='password' placeholder='Password' required />
								<InputGroup.Text className='eye-background' onClick={passwordVisibility}>
									<div>
										{seePasswordEye} {seePasswordEyeSlash}{" "}
									</div>{" "}
								</InputGroup.Text>
							</InputGroup>
						</Form.Group>

						<Form.Group controlId='formBasicPasswordConfirm'>
							<Form.Label>Confirm Password</Form.Label>
							<InputGroup className='mb-2'>
								<Form.Control
									onChange={userPasswordConfirmChangeHandler}
									value={enteredUserPasswordConfirm}
									type='password'
									placeholder='Confirm Password'
									required
								/>
								<InputGroup.Text className='eye-background' onClick={passwordConfirmVisibility}>
									<div>
										{seePasswordConfirmEye} {seePasswordConfirmEyeSlash}{" "}
									</div>{" "}
								</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check type='checkbox' label='Set user as an admin.' checked={enteredAdminIsChecked} onChange={userAdminChangehandler} />
						</Form.Group>
						<Button variant='primary' type='submit'>
							Submit
						</Button>
						<Button variant='dark' className='mx-3' onClick={cancelUserCreate}>
							{" "}
							Cancel{" "}
						</Button>
					</Form>
				</div>
			</Container>
		</div>
	);
}

export default AdminAddUser;
