import React, { useState, useContext } from "react";
import { Container, Col, Form, Button, FormControl, Spinner } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { AuthContext } from "../../shared/context/auth-context";

import "./NewSystem.css";

const NewSystem = (props) => {
	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();
	const auth = useContext(AuthContext);

	const [enteredCustomerName, setEnteredCustomerName] = useState("");
	const [enteredSerialNumber, setEnteredSerialNumber] = useState("");
	const [enteredAddress, setEnteredAddress] = useState("");
	const [enteredSystemAdminName, setEnteredSystemAdminName] = useState("");
	const [enteredSystemAdminEmail, setEnteredSystemAdminEmail] = useState("");
	const [enteredSystemAdminPhone, setEnteredSystemAdminPhone] = useState("");
	const [enteredCity, setEnteredCity] = useState("");
	const [enteredState, setEnteredState] = useState("");
	const [enteredZip, setEnteredZip] = useState("");
	const [enteredCountry, setEnteredCountry] = useState("");
	const [enteredSystemType, setEnteredSystemType] = useState("");
	const [enteredContactForLogs, setEnteredContactForLogs] = useState("");
	const [enteredContactEmailForLogs, setEnteredContactEmailForLogs] = useState("");
	const [enteredContactPhoneForLogs, setEnteredContactPhoneForLogs] = useState("");
	const [enteredShippingAddress, setEnteredShippingAddress] = useState("");
	const [enteredLabor, setEnteredLabor] = useState("");
	const [enteredLaborContactEmail, setEnteredLaborContactEmail] = useState("");
	const [enteredLaborContactPhone, setEnteredLaborContactPhone] = useState("");
	const [enteredNotes, setEnteredNotes] = useState("");
	const [enteredFtp, setEnteredFtp] = useState("");

	const addSystemHandler = async (event) => {
		event.preventDefault();

		const newSystem = {
			creator: auth.userId,
			customerName: enteredCustomerName,
			serialNum: enteredSerialNumber,
			address: enteredAddress,
			systemAdminName: enteredSystemAdminName,
			systemAdminEmail: enteredSystemAdminEmail,
			systemAdminPhone: enteredSystemAdminPhone,
			city: enteredCity,
			state: enteredState,
			zip: enteredZip,
			country: enteredCountry,
			systemType: enteredSystemType,
			contactForLogs: enteredContactForLogs,
			contactEmailForLogs: enteredContactEmailForLogs,
			contactPhoneForLogs: enteredContactPhoneForLogs,
			shippingAddress: enteredShippingAddress,
			labor: enteredLabor,
			laborContactEmail: enteredLaborContactEmail,
			laborContactPhone: enteredLaborContactPhone,
			notes: enteredNotes,
			ftp: enteredFtp,
		};

		try {
			await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/systems`, "POST", JSON.stringify(newSystem), {
				"Content-Type": "application/json",
				Authorization: "Bearer " + auth.token,
			});

			setEnteredCustomerName("");
			setEnteredSerialNumber("");
			setEnteredAddress("");
			setEnteredSystemAdminName("");
			setEnteredSystemAdminEmail("");
			setEnteredSystemAdminPhone("");
			setEnteredCity("");
			setEnteredState("");
			setEnteredZip("");
			setEnteredCountry("");
			setEnteredSystemType("");
			setEnteredContactForLogs("");
			setEnteredContactEmailForLogs("");
			setEnteredContactPhoneForLogs("");
			setEnteredShippingAddress("");
			setEnteredLabor("");
			setEnteredLaborContactEmail("");
			setEnteredLaborContactPhone("");
			setEnteredNotes("");
			setEnteredFtp("");

			props.showModal(true);
		} catch (err) {}
	};

	const customerNameChangeHandler = (event) => {
		setEnteredCustomerName(event.target.value);
	};

	const serialNumberChangeHandler = (event) => {
		setEnteredSerialNumber(event.target.value);
	};

	const systemAdminNameChangeHandler = (event) => {
		setEnteredSystemAdminName(event.target.value);
	};

	const systemAdminEmailChangeHandler = (event) => {
		setEnteredSystemAdminEmail(event.target.value);
	};

	const systemAdminPhoneChangeHandler = (event) => {
		setEnteredSystemAdminPhone(event.target.value);
	};

	const addresChangeHandler = (event) => {
		setEnteredAddress(event.target.value);
	};

	const cityChangeHandler = (event) => {
		setEnteredCity(event.target.value);
	};

	const stateChangeHandler = (event) => {
		setEnteredState(event.target.value);
	};

	const zipChangeHandler = (event) => {
		setEnteredZip(event.target.value);
	};

	const countryChangeHandler = (event) => {
		setEnteredCountry(event.target.value);
	};

	const systemTypeChangeHandler = (event) => {
		setEnteredSystemType(event.target.value);
	};

	const contactForLogsChangeHandler = (event) => {
		setEnteredContactForLogs(event.target.value);
	};

	const contactEmailForlogsChangeHandler = (event) => {
		setEnteredContactEmailForLogs(event.target.value);
	};

	const contactPhoneForlogsChangeHandler = (event) => {
		setEnteredContactPhoneForLogs(event.target.value);
	};

	const laborChangeHandler = (event) => {
		setEnteredLabor(event.target.value);
	};

	const laborContactEmailChangeHandler = (event) => {
		setEnteredLaborContactEmail(event.target.value);
	};

	const laborContactPhoneChangeHandler = (event) => {
		setEnteredLaborContactPhone(event.target.value);
	};

	const shippingAddressChangeHandler = (event) => {
		setEnteredShippingAddress(event.target.value);
	};

	const notesChangeHandler = (event) => {
		setEnteredNotes(event.target.value);
	};

	const ftpChangeHandler = (event) => {
		setEnteredFtp(event.target.value);
	};

	return (
		<div>
			<Container>
				<ErrorMessageModel
					show={errorModalShow}
					onHide={() => {
						setErrorModalShow(false);
					}}
					message={error}
				/>

				{isLoading && (
					<div className='spinner-loading'>
						<Spinner animation='border' variant='primary' /> <span>Loading...</span>
					</div>
				)}

				<Form onSubmit={addSystemHandler} autoComplete='off'>
					<Form.Row>
						<Form.Group as={Col} md controlId='formGridCustomerName'>
							<Form.Label>Customer Name</Form.Label>
							<Form.Control onChange={customerNameChangeHandler} value={enteredCustomerName} type='text' placeholder='Customer Name' required />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridSerialNumber'>
							<Form.Label>Serial Number</Form.Label>
							<Form.Control onChange={serialNumberChangeHandler} value={enteredSerialNumber} type='text' placeholder='Enter Serial Number' required />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md controlId='formGridSystemAdminName'>
							<Form.Label>System Admin Name </Form.Label>
							<Form.Control onChange={systemAdminNameChangeHandler} value={enteredSystemAdminName} type='text' placeholder='Name' />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridSystemAdminEmail'>
							<Form.Label>System Admin Email </Form.Label>
							<Form.Control onChange={systemAdminEmailChangeHandler} value={enteredSystemAdminEmail} type='email' placeholder='Enter Email' />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridSystemAdminPhone'>
							<Form.Label>System Admin phone number </Form.Label>
							<Form.Control
								onChange={systemAdminPhoneChangeHandler}
								value={enteredSystemAdminPhone}
								type='tel'
								placeholder='Enter Phone 312-555-1234'
							/>
						</Form.Group>
					</Form.Row>

					<Form.Group controlId='formGridAddress1'>
						<Form.Label>Address</Form.Label>
						<Form.Control onChange={addresChangeHandler} value={enteredAddress} placeholder='1234 Main St' />
					</Form.Group>

					<Form.Row>
						<Form.Group as={Col} sm controlId='formGridCity'>
							<Form.Label>City</Form.Label>
							<Form.Control onChange={cityChangeHandler} value={enteredCity} placeholder='City' />
						</Form.Group>

						<Form.Group as={Col} sm controlId='formGridState'>
							<Form.Label>State</Form.Label>
							<Form.Control onChange={stateChangeHandler} value={enteredState} />
						</Form.Group>

						<Form.Group as={Col} sm controlId='formGridZip'>
							<Form.Label>Zip</Form.Label>
							<Form.Control onChange={zipChangeHandler} value={enteredZip} />
						</Form.Group>

						<Form.Group as={Col} sm>
							<Form.Label>Country</Form.Label>
							<Form.Control onChange={countryChangeHandler} value={enteredCountry} />
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md controlId='formGridSystemType'>
							<Form.Label>System Type </Form.Label>
							<Form.Control onChange={systemTypeChangeHandler} value={enteredSystemType} type='text' placeholder='Enter System Type and model' />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridContactLog'>
							<Form.Label>Contact For logs </Form.Label>
							<Form.Control onChange={contactForLogsChangeHandler} value={enteredContactForLogs} type='text' placeholder='Enter Contact Name' />
							<Form.Label>Contact Phone # For Logs</Form.Label>
							<Form.Control
								onChange={contactPhoneForlogsChangeHandler}
								value={enteredContactPhoneForLogs}
								type='text'
								placeholder='Enter Contact Phone #'
							/>
							<Form.Label>Contact Email for logs</Form.Label>
							<Form.Control
								onChange={contactEmailForlogsChangeHandler}
								value={enteredContactEmailForLogs}
								type='email'
								placeholder='name@example.com'
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} md controlId='formGridLabor'>
							<Form.Label>Larbor </Form.Label>
							<Form.Control onChange={laborChangeHandler} value={enteredLabor} type='text' placeholder='Enter who does the labor (i.e. IBM or UTC)' />
							<Form.Label> Labor Email</Form.Label>
							<FormControl
								onChange={laborContactEmailChangeHandler}
								value={enteredLaborContactEmail}
								type='text'
								placeholder='Enter Labor Email address'
							/>
							<Form.Label> Labor Phone</Form.Label>
							<FormControl
								onChange={laborContactPhoneChangeHandler}
								value={enteredLaborContactPhone}
								type='tel'
								placeholder='Example: 432-555-3689'
							/>
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridShippingAddress'>
							<Form.Label>Shipping Address </Form.Label>
							<Form.Control
								onChange={shippingAddressChangeHandler}
								value={enteredShippingAddress}
								as='textarea'
								rows={4}
								placeholder='Shipping Address for system'
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md controlId='formGridNotes'>
							<Form.Label>Enter Notes</Form.Label>
							<Form.Control onChange={notesChangeHandler} value={enteredNotes} as='textarea' rows={4} />
						</Form.Group>

						<Form.Group as={Col} md controlId='formGridFtp'>
							<Form.Label>Enter FTP info </Form.Label>
							<Form.Control onChange={ftpChangeHandler} value={enteredFtp} as='textarea' rows={4} />
						</Form.Group>
					</Form.Row>

					<Button variant='primary' type='submit'>
						Submit
					</Button>
				</Form>
			</Container>
		</div>
	);
};

export default NewSystem;
