import React, { useContext } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import { AuthContext } from "../context/auth-context";

import "./DeleteValidation.css";

function DeleteValidation(props) {
	const auth = useContext(AuthContext);
	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();
	function cancelDelete() {
		props.onHide();
	}

	async function deleteSystem() {
		let cleanSystemToDelete = encodeURIComponent(props.systemToDelete.id.trim());
		try {
			await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/systems/${cleanSystemToDelete}`, "DELETE", null, {
				Authorization: "Bearer " + auth.token,
			});

			let filteredSystemListNew = [];
			props.updateResults(filteredSystemListNew);
			props.onHide();
			window.history.go(-2);
		} catch (err) {}
	}

	return (
		<>
			<ErrorMessageModel
				show={errorModalShow}
				onHide={() => {
					setErrorModalShow(false);
				}}
				message={error}
			/>

			{isLoading && (
				<div className='spinner-loading'>
					<Spinner animation='border' variant='primary' /> <span>Loading...</span>
				</div>
			)}

			<Modal {...props} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title> Delete System</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you would like to delete this syetem? The action can not be undone.</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={cancelDelete}>
						Cancel
					</Button>
					<Button variant='danger' onClick={deleteSystem}>
						Delete System
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default DeleteValidation;
