import React, { useState, useContext } from "react";
import { Form, Button, Col, Alert, Spinner } from "react-bootstrap";
import { useHttpClient } from "../../hooks/http-hook";
import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";

import "./SearchUser.css";

import { AuthContext } from "../../shared/context/auth-context";

import useVisibilityToggler from "../../hooks/useVisibilityToggler";

const SearchUser = (props) => {
	const [enteredSearchValueUser, setEnteredSearchValueUser] = useState("");
	const auth = useContext(AuthContext);
	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	const searchUserValueChangeHadler = (event) => {
		hideNoEmailEntered();
		hideNoResult();
		setEnteredSearchValueUser(event.target.value.toLowerCase());
	};

	const searchUserHandler = async (event) => {
		event.preventDefault();

		if (!enteredSearchValueUser) {
			showNoEmailEntered();
			hideNoResult();
		} else {
			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/api/users/`,
					"POST",
					JSON.stringify({
						userEmail: enteredSearchValueUser,
					}),
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + auth.token,
					}
				);

				props.updateUserResult(responseData.users);
				setEnteredSearchValueUser("");
			} catch (err) {}
		}
	};

	const clearSearchResualts = () => {
		let filteredUserListNew = [];
		props.updateUserResult(filteredUserListNew);
	};

	const [alertConponent, showNoEmailEntered, hideNoEmailEntered] = useVisibilityToggler(
		<Alert variant='danger' onClose={() => hideNoEmailEntered()} dismissible>
			Please enter a Email Address
		</Alert>,
		false
	);

	const [alertConponentInfo /* showNoResult */, , hideNoResult] = useVisibilityToggler(
		<Alert variant='warning' onClose={() => hideNoResult()} dismissible>
			No User found
		</Alert>,
		false
	);

	return (
		<div>
			<h2 className='search-user-header'>Search User</h2>

			<ErrorMessageModel
				show={errorModalShow}
				onHide={() => {
					setErrorModalShow(false);
				}}
				message={error}
			/>

			{isLoading && (
				<div className='spinner-loading'>
					<Spinner animation='border' variant='primary' /> <span>Loading...</span>
				</div>
			)}

			<Form onSubmit={searchUserHandler} autoComplete='off'>
				<Form.Row>
					<Form.Group as={Col} controlId='formGridSearchUser'>
						<div className='alertHight'>
							{" "}
							{alertConponent} {alertConponentInfo}{" "}
						</div>
						<Form.Label>Search by email address </Form.Label>
						<Form.Control onChange={searchUserValueChangeHadler} value={enteredSearchValueUser} type='text' placeholder='Enter email address' />
					</Form.Group>
				</Form.Row>

				<Button variant='primary' type='submit'>
					Search
				</Button>
				<Button variant='dark' className='mx-3' onClick={clearSearchResualts}>
					{" "}
					Clear
				</Button>
			</Form>
		</div>
	);
};

export default SearchUser;
