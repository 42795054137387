import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Jumbotron, Button, Container, Form, Spinner } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";

import { AuthContext } from "../../shared/context/auth-context";

import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";
import MessageUserUpdate from "../../shared/messageUpdate/MessageUserUpdate";

import "./EditUser.css";

const EditUser = (props) => {
	const userId = useParams().userId;
	const auth = useContext(AuthContext);

	const [editUserFirstName, setEditUserFirstName] = useState("");
	const [editUserLastName, setEditUserLastName] = useState("");
	const [editUserEmail, setEditUserEmail] = useState("");
	const [editAdminIsChecked, setEditAdminIsChecked] = useState(false);

	const [showUserAdd, setShowUserAdd] = useState(false);

	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	const clearSearchResualts = () => {
		let filteredUserListNew = [];
		props.updateUserResult(filteredUserListNew);
	};

	//let currentUser = props.users.find((tempUsers) => tempUsers.uid === userId) ;
	let [currentUser, setCurrentUser] = useState();

	useEffect(() => {
		const getUser = async () => {
			try {
				let cleanUserId = encodeURIComponent(userId);
				const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/users/getuserbyid/${cleanUserId}`, "GET", null, {
					Authorization: "Bearer " + auth.token,
				});
				setCurrentUser(responseData.users);
				setEditUserFirstName(responseData.users.userFirstName);
				setEditUserLastName(responseData.users.userLastName);
				setEditUserEmail(responseData.users.userEmail);
				setEditAdminIsChecked(responseData.users.admin);
			} catch (err) {}
		};
		getUser();
	}, [sendRequest, userId, auth.token]);

	let userFound = false;

	if (currentUser) {
		userFound = true;
	} else {
		currentUser = {};
	}

	const editUserFormHandler = async (event) => {
		event.preventDefault();

		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/api/users/update/userinfo`,
				"PATCH",
				JSON.stringify({
					userFirstName: editUserFirstName,
					userLastName: editUserLastName,
					userEmail: editUserEmail,
					admin: editAdminIsChecked,
				}),

				{
					"Content-Type": "application/json",
					Authorization: "Bearer " + auth.token,
				}
			);

			clearSearchResualts();
			setShowUserAdd(true);
		} catch (err) {}
	};

	const deleteUserHandler = () => {
		//alert("Delete user works");
		const deleteUser = {
			uid: currentUser.id,
			userFirstName: currentUser.userFirstName,
			userLastName: currentUser.userLastName,
			userEmail: currentUser.userEmail,
			userPassword: currentUser.userPassword,
			userPasswordConfirm: currentUser.userPasswordConfirm,
			admin: currentUser.admin,
		};

		props.setUserToDelete((user) => {
			return (user = deleteUser);
		});

		props.showDeleteUserModal(true);
	};

	const userFirstNameChangeHandler = (event) => {
		setEditUserFirstName(event.target.value);
	};

	const userLastNameChangeHandler = (event) => {
		setEditUserLastName(event.target.value);
	};
	const userEmailChangeHandler = (event) => {
		setEditUserEmail(event.target.value);
	};

	const userAdminChangehandler = () => {
		setEditAdminIsChecked(!editAdminIsChecked);
	};

	function cancelUserEdit() {
		window.history.go(-1);
	}

	if (userFound) {
		return (
			<div>
				<Container>
					<h1 className='edit-user-heading'>Edit User</h1>

					<ErrorMessageModel
						show={errorModalShow}
						onHide={() => {
							setErrorModalShow(false);
						}}
						message={error}
					/>

					<MessageUserUpdate show={showUserAdd} onHide={() => setShowUserAdd(false)} userId={userId} />

					<div className='button-container'>
						<Button onClick={deleteUserHandler} className='del-button' variant='danger'>
							Delete
						</Button>
					</div>
					{isLoading && (
						<div className='spinner-loading'>
							<Spinner animation='border' variant='primary' /> <span>Updating...</span>
						</div>
					)}
					<Form onSubmit={editUserFormHandler} autoComplete='off'>
						<Form.Group>
							<div className='alertHight'> </div>
							<Form.Label> First Name </Form.Label>
							<Form.Control onChange={userFirstNameChangeHandler} value={editUserFirstName} placeholder='First Name' required />
						</Form.Group>

						<Form.Group>
							<Form.Label> Last Name </Form.Label>
							<Form.Control onChange={userLastNameChangeHandler} value={editUserLastName} placeholder='Last Name' required />
						</Form.Group>

						<Form.Group>
							<Form.Label> Email </Form.Label>
							<Form.Control onChange={userEmailChangeHandler} value={editUserEmail} type='email' placeholder='Email' required disabled />
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check type='checkbox' label='Set user as an admin.' checked={editAdminIsChecked} onChange={userAdminChangehandler} />
						</Form.Group>
						<Button variant='primary' type='submit'>
							Submit
						</Button>
						<Button variant='dark' className='mx-3' onClick={cancelUserEdit}>
							{" "}
							Cancel{" "}
						</Button>
					</Form>
				</Container>
			</div>
		);
	} else {
		return (
			<div>
				<Container>
					<div className='edit-user-jumbo'>
						<ErrorMessageModel
							show={errorModalShow}
							onHide={() => {
								setErrorModalShow(false);
							}}
							message={error}
						/>

						{isLoading ? (
							<div className='spinner-loading'>
								<Spinner animation='border' variant='primary' /> <span>Loading...</span>
							</div>
						) : (
							<Jumbotron>
								<h1> User Not Found </h1>
								<p>The user you have entered is incorrect. Please return to the user edit page to continue.</p>
								<p>
									<Link to='/system/backend/updateuser'>
										<Button variant='primary'>Back to User Edit</Button>{" "}
									</Link>
								</p>
							</Jumbotron>
						)}
					</div>
				</Container>
			</div>
		);
	}
};

export default EditUser;
