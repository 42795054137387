import React from 'react';
import {Modal, Button } from "react-bootstrap";

import './ErrorMessageModel.css'

function ErrorMessageModel(props) {
    return (
        <Modal {...props} >
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body className="error-message">Error:  {props.message} </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    )
}

export default ErrorMessageModel
