import React, { useState, useEffect } from "react";
import { Container, Button, Spinner, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import ErrorMessageModel from "../../shared/messageUpdate/ErrorMessageModel";
import { useHttpClient } from "../../hooks/http-hook";

import "./VerifyEmail.css";

const VerifyEmail = () => {
	const emailVerifyString = useParams().emailVerifyString;

	const [emailVerified, setEmailVerified] = useState(false);
	const { isLoading, error, errorModalShow, setErrorModalShow, sendRequest } = useHttpClient();

	useEffect(() => {
		const getUser = async () => {
			let cleanEmailVerifyString = encodeURIComponent(emailVerifyString.trim());
			try {
				await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/api/users/verify/email/${cleanEmailVerifyString}`, "PATCH");
				setEmailVerified(true);
			} catch (err) {}
		};
		getUser();
	}, [sendRequest, emailVerifyString]);

	return (
		<>
			<Container>
				<ErrorMessageModel
					show={errorModalShow}
					onHide={() => {
						setErrorModalShow(false);
					}}
					message={error}
				/>
				{!emailVerified && (
					<div className='p-5 mb-4 bg-light rounded-3'>
						<div className='container-fluid py-5'>
							<h1 className='display-5 fw-bold'>Verify Email</h1>
							<h2> Verifing</h2>
							<div className='text-center'>
								{error && <Alert variant='danger'> {error} </Alert>}
								{error && (
									<div className='col-3 mx-auto'>
										<Button href='/verify/resend/email/' className='mt-4 '>
											Resend code
										</Button>
									</div>
								)}
								{isLoading && (
									<p>
										<Spinner animation='grow' variant='primary' />
										<Spinner animation='grow' variant='secondary' />
										<Spinner animation='grow' variant='success' />
										<Spinner animation='grow' variant='primary' />
										<Spinner animation='grow' variant='secondary' />
										<Spinner animation='grow' variant='success' />
										<Spinner animation='grow' variant='primary' />
										<Spinner animation='grow' variant='secondary' />
										<Spinner animation='grow' variant='success' />
										<Spinner animation='grow' variant='primary' />
										<Spinner animation='grow' variant='secondary' />
										<Spinner animation='grow' variant='success' />
									</p>
								)}
							</div>
						</div>
					</div>
				)}
				{emailVerified && (
					<div className='p-5 mb-4 bg-light rounded-3'>
						<div className='container-fluid py-5'>
							<h1 className='display-5 fw-bold'>Verify Email</h1>
							<p className='col-md-8 fs-4'>Your accout has been verified. You my now proceed to login.</p>
							<Button href='/login' className='btn btn-primary btn-lg' type='button'>
								Proceed to login{" "}
							</Button>
						</div>
					</div>
				)}
			</Container>
		</>
	);
};

export default VerifyEmail;
