import React from 'react'
import './Search.css'


const Search = (props) => {
    return (
        <div>
            <h2 >Search System!</h2>
            
        </div>
    )
}

export default Search
