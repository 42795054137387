import { useState, useCallback, useRef, useEffect } from 'react';


export const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [errorModalShow, setErrorModalShow] = useState(false);

    const activeHttpRequests = useRef([]); 

    const sendRequest = useCallback (async (url, method = 'GET', body = null, headers = {} ) => {
        setIsLoading(true);
     
        const httpAbortCtrl = new AbortController();
        activeHttpRequests.current.push(httpAbortCtrl);

      try { const response = await fetch(url, {
            method: method,
            body,
            headers,
            signal: httpAbortCtrl.signal 
        });

        const responseData = await response.json();

      if (!response.ok){
        throw new Error (responseData.message);
      }

      activeHttpRequests.current = activeHttpRequests.current.filter(reqCtrl => reqCtrl !== httpAbortCtrl);

      setIsLoading(false);
      return responseData;
    } catch (err) {
        setError(err.message || 'Something went wrong, Please try again.');
        setErrorModalShow(true);
        setIsLoading(false);
      throw err;  
    }
   
    }, []);

    const clearError = () => {
        setError(null);
    };

    useEffect(() => {
        return () => {
            activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
        };
    }, [] );

    return { isLoading, error, errorModalShow, setIsLoading, setError, setErrorModalShow, sendRequest, clearError}
}; 